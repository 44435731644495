var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "taben page df fdc jc-sb" },
    [
      _c("div", [
        _vm.login_img
          ? _c("img", {
              staticClass: "img-bg",
              attrs: { src: _vm.login_img, alt: "" },
            })
          : !_vm.login_img && _vm.img_request
          ? _c("img", {
              staticClass: "img-bg",
              attrs: {
                src: require("../../assets/img_denglu_bg@3x.png"),
                alt: "",
              },
            })
          : _vm._e(),
        _c("h3", { staticClass: "title" }, [_vm._v("Hi，请登录")]),
        _c("div", { staticClass: "form" }, [
          _c("div", { staticClass: "cell" }, [
            _c("i", { staticClass: "icon icon-mobile" }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.mobile,
                  expression: "mobile",
                },
              ],
              attrs: {
                type: "number",
                placeholder: "请输入你的手机号",
                maxlength: "11",
              },
              domProps: { value: _vm.mobile },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.mobile = $event.target.value
                },
              },
            }),
          ]),
          _c("div", { staticClass: "cell" }, [
            _c("i", { staticClass: "icon icon-img-code" }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.code,
                  expression: "code",
                },
              ],
              attrs: {
                type: "text",
                maxlength: "6",
                placeholder: "请输入验证码",
              },
              domProps: { value: _vm.code },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.code = $event.target.value
                },
              },
            }),
            _c(
              "div",
              {
                staticClass: "img-box",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.getRandomImgCode.apply(null, arguments)
                  },
                },
              },
              [
                _c("img", {
                  staticClass: "img-code",
                  attrs: { src: _vm.img_code },
                }),
              ]
            ),
          ]),
          _c("div", { staticClass: "cell" }, [
            _c("i", { staticClass: "icon icon-sms-code" }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.sms_code,
                  expression: "sms_code",
                },
              ],
              attrs: {
                type: "number",
                maxlength: "6",
                placeholder: "请输入短信验证码",
              },
              domProps: { value: _vm.sms_code },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.sms_code = $event.target.value
                },
              },
            }),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.code_bool,
                    expression: "code_bool",
                  },
                ],
                staticClass: "btn send-btn flex-center",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.getSmsCode.apply(null, arguments)
                  },
                },
              },
              [_vm._v("发送验证码")]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.code_bool,
                    expression: "!code_bool",
                  },
                ],
                staticClass: "countdown flex-center",
              },
              [_vm._v(_vm._s(_vm.count) + "s")]
            ),
          ]),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass: "btn submit-btn flex-center",
          on: { click: _vm.submit },
        },
        [_vm._v("立即登录")]
      ),
      _c("alert-tip", {
        attrs: {
          type: _vm.type,
          "show-alert": _vm.show_tip,
          "alert-text": _vm.tip,
        },
        on: { changeShowAlert: _vm.changeShow },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }