<template>
	<div class="taben page df fdc jc-sb">
		<div>
			<!-- S 背景图 -->
			<img v-if="login_img" class="img-bg" :src="login_img" alt="" />
			<img v-else-if="!login_img && img_request" class="img-bg" src="../../assets/img_denglu_bg@3x.png" alt="" />
			<!-- E 背景图 -->

			<h3 class="title">Hi，请登录</h3>
			<div class="form">
				<div class="cell">
					<i class="icon icon-mobile"></i>
					<input type="number" v-model="mobile" placeholder="请输入你的手机号" maxlength="11">
				</div>
				<div class="cell">
					<i class="icon icon-img-code"></i>
					<input type="text" v-model="code" maxlength="6" placeholder="请输入验证码" />
					<div class="img-box" @click.prevent="getRandomImgCode"><img :src="img_code" class="img-code" /></div>
				</div>
				<div class="cell">
					<i class="icon icon-sms-code"></i>
					<input type="number" v-model="sms_code" maxlength="6" placeholder="请输入短信验证码" />
					<div class="btn send-btn flex-center" v-show="code_bool" @click.prevent="getSmsCode">发送验证码</div>
					<div class="countdown flex-center" v-show="!code_bool">{{ count }}s</div>
				</div>
			</div>
		</div>

		<div class="btn submit-btn flex-center" @click="submit">立即登录</div>

		<!-- S 提示弹窗 -->
		<alert-tip :type="type" :show-alert="show_tip" :alert-text="tip" @changeShowAlert="changeShow"></alert-tip>
		<!-- E 提示弹窗 -->
	</div>
</template>

<script>
import Validator from '@/utils/validator';
import AlertTip from '@/components/common/AlertTip';
import { toolTip, inputMixins } from '@/mixins';
import { debounce } from '@/utils/debounce';
import { mapMutations, mapState } from 'vuex';
import { codeLogin, getColor, getImgCode, sendVerifyCode } from '@/services';

let base_code = '';
const COMPUTED_TIME = 60;
export default {
	name: 'TabenLogin',
	components: {
		AlertTip
	},
	mixins: [toolTip, inputMixins],
	data() {
		return {
			mobile: '',
			login_img: '', // 登录图
			code: '', //验证码
			sms_code: '', // 短信验证码
			img_code: '', // 图形验证码
			code_bool: true, // 控制显示获取验证码
			count: '', // 倒计时
			timer: null, // 计时器
			txtColor: null //字体颜色
		};
	},
	computed: {
		...mapState({
			businessType: state => state.businessInfo.type, // 1现金商城，2积分商品，3内采商城
			userInfo: state => state.userInfo //用户信息
		})
	},
	methods: {
		...mapMutations(['getUser', 'getSessionId']),

		// 获取主题色
		async getThemeColor() {
			try {
				const res = await getColor(this.$store.state.userInfo.business_id);
				this.img_request = true;
				if (res.code === 0) {
					const { color, login_img, login_text } = res.data;
					this.login_img = login_img;
					this.login_text = login_text;
					this.txtColor = color;
					document.body.style.setProperty('--main-color', color);
					this.$forceUpdate();
				}
			} catch (error) {
				console.warn('Something bad happened in api getColor: ', error);
			}
		},

		// 获取图形验证码
		async loadImgCode() {
			const res = await getImgCode();
			if (res.code === 0) {
				this.img_code = res.data.url;
				base_code = res.data.url;
			} else {
				if (process.env.NODE_ENV === 'development') {
					this.show_layer('cancel', res.error_msg);
				}
			}
		},

		// 切换图形验证码
		getRandomImgCode: debounce(function() {
			this.img_code = base_code + '&' + Math.random();
		}, 300),

		// 获取短信验证码
		getSmsCode: debounce(async function() {
			let mobile = this.mobile.replace(/\s+/g, ''),
				code = this.code.replace(/\s+/g, '');
			const { result, errorMessage } = Validator.validate('mobile', mobile);
			if (!mobile) {
				this.show_layer('cancel', '请输入手机号码');
				return;
			}
			if (!result) {
				this.show_layer('cancel', errorMessage);
				return;
			}
			if (!code) {
				this.show_layer('cancel', '请输入图形验证码');
				return;
			}
			if (this.timer) {
				clearInterval(this.timer);
			}
			const res = await sendVerifyCode(mobile, 'login', code);
			if (res.code === 0) {
				this.show_layer('success', '验证码已发送，请注意查收');
				this.count = COMPUTED_TIME;
				this.code_bool = false;
				this.timer = setInterval(() => {
					if (this.count > 0) {
						this.count--;
					} else {
						this.code_bool = true;
						clearInterval(this.timer);
						this.timer = null;
					}
				}, 1000);
			} else {
				this.show_layer('cancel', res.msg);
			}
		}, 300),

		// 登录
		submit: debounce( async function () {
			this.is_request = true;
			let mobile = this.mobile.replace(/\s+/g, ""),
				sms_code = this.sms_code.replace(/\s+/g, "");
			const {result, errorMessage} = Validator.validate("mobile", mobile);
			if (!mobile) {
				this.show_layer("cancel", "请输入账号");
				this.is_request = false
				return;
			}
			if (!result) {
				this.show_layer("cancel", errorMessage);
				this.is_request = false
				return;
			}
			try {
				const res = await codeLogin(mobile, sms_code);
				this.is_request = false;
				if (res.code === 0) {
					const {PHPSESSID, user} = res.data;
					const show = res.data.is_show;
					if (show) {
						sessionStorage.setItem('show', show)
					} else {
						sessionStorage.setItem('show', '')
					}
					SID = PHPSESSID;
					this.getSessionId(PHPSESSID);
					this.show_layer("success", "登录成功");
					this.$store.dispatch("getUserInfo");
					await this.getUser(user);
					await this.getThemeColor();
					try {
						const data = await this.$store.dispatch('getBusinessRate');
						if (JSON.stringify(data) !== '{}') {
							this.$router.replace('home');
						}
					} catch (e) {
						console.warn(e);
					}
				} else {
					this.show_layer("cancel", res.msg);
				}
			} catch (error) {
				console.warn("Something bad happened in api login: ", error);
			}
		}, 300)
	},
	mounted() {
		document.title = this.$route.meta.title;
		this.getThemeColor();
		this.loadImgCode();
	},
	beforeDestroy() {
		clearInterval(this.timer);
	}
};
</script>

<style lang="less" scoped>
@import '../../common/less/variable.less';
@import '../../common/less/mixin.less';

.page {
	.bj(#fff);
	padding-bottom: 0.6rem;
	.img-bg {
		.wh(100%, 3.64rem);
	}
	.title {
		padding-left: 0.52rem;
		font-size: 0.4rem;
		color: #000000;
		font-weight: bold;
		margin-top: 0.3rem;
	}

	.form {
		padding: 0 0.56rem 0 0.54rem;
		margin-bottom: 0.2rem;

		.cell {
			position: relative;
			display: flex;
			align-items: center;
			width: 100%;
			padding-left: 0.52rem;
			padding-top: 0.4rem;
			padding-bottom: 0.3rem;
			border-bottom: 1px solid @color-row-line;

			.icon {
				position: absolute;
				top: 0.44rem;
				left: 0;
				display: inline-block;
				.wh(0.32rem, 0.32rem);
				z-index: 2;
			}

			input {
				.wh(100%, 0.4rem);
				.sc(@fontsize-medium, @color-dark-grey);
				line-height: 0.4rem;

				&::-webkit-input-placeholder {
					color: #b3b3b3;
				}
			}

			.icon-mobile {
				width: 0.24rem;
				.bis('../../assets/icon_denglu_shouji');
			}

			.icon-password {
				width: 0.28rem;
				.bis('../../assets/icon_denglu_mima');
			}

			.icon-img-code {
				height: 0.28rem;
				.bis('../../assets/icon_denglu_yanzhengma');
			}

			.icon-sms-code {
				.bis('../../assets/icon_denglu_shuruyanzhengma');
			}

			.img-box {
				.ct;
				right: 0;
				.wh(2.4rem, 0.8rem);
				z-index: 4;

				.img-code {
					.wh(100%, 100%);
				}
			}

			.send-btn,
			.countdown {
				.ct;
				right: 0;
				.wh(1.68rem, 0.52rem);
				.sc(@fontsize-small, #fff);
				line-height: 0.52rem;
				text-align: center;
				white-space: nowrap;
				border-radius: 0.26rem;
				z-index: 4;
			}

			.send-btn {
				background: var(--main-color);
				.touch-active;
			}

			.countdown {
				background: @color-light-grey;
			}
		}
	}

	.form-bottom {
		position: relative;
		display: flex;
		height: 0.34rem;
		padding-left: 0.56rem;
		padding-right: 0.54rem;

		.forget-pwd-link {
			.ct;
			right: 0.54rem;
			.sc(@fontsize-small, @color-dark-grey);
			line-height: 0.34rem;
			white-space: nowrap;
			z-index: 2;
		}
	}

	.submit-btn {
		.wh(6.4rem, 0.88rem);
		margin: 0 auto;
		.sc(@fontsize-large, #fff);
		line-height: 0.88rem;
		text-align: center;
		white-space: nowrap;
		background-color: var(--main-color);
		border-radius: 0.5rem;
		overflow: hidden;
		.touch-active(var(--main-color));
	}
}
</style>
